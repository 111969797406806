import { Routes, Route } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolflareWebWallet,
  getSolletWallet,
  getSolletExtensionWallet,
  getSolongWallet,
  getLedgerWallet,
  getSafePalWallet,
} from "@solana/wallet-adapter-wallets";

import { Commitment, Connection, LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useWallet } from "@solana/wallet-adapter-react";
import { getAtaForMint } from "./utils";
import { getCandyMachineState } from "./candy-machine";

import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { DEFAULT_TIMEOUT } from "./connection";

// import "./App.css";
import Home from "./pages/home/Index.jsx";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import MintPage from "./pages/mint";

require("@solana/wallet-adapter-react-ui/styles.css");

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!
    );

    return candyMachineId;
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
);

const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS
  ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString()
  : 9;

const App = () => {
  // Custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolflareWebWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
      getSolongWallet(),
      getLedgerWallet(),
      getSafePalWallet(),
    ],
    []
  );

  const [balance, setBalance] = useState<number>();

  const [isEnded, setIsEnded] = useState(false);
  const [isPresale, setIsPresale] = useState(false);

  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) return;

      const connection = new Connection(rpcHost, commitment);

      if (candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            candyMachineId,
            connection
          );

          var divider = 1;
          if (decimals) {
            divider = +("1" + new Array(decimals).join("0").slice() + "0");
          }

          // fetch whitelist token balance
          if (cndy.state.whitelistMintSettings) {
            setIsPresale(cndy.state.whitelistMintSettings.presale);

            if (
              cndy.state.whitelistMintSettings.discountPrice !== null &&
              cndy.state.whitelistMintSettings.discountPrice !==
                cndy.state.price
            ) {
              if (cndy.state.tokenMint) {
              }
            }

            let balance = 0;
            try {
              const tokenBalance = await connection.getTokenAccountBalance(
                (
                  await getAtaForMint(
                    cndy.state.whitelistMintSettings.mint,
                    anchorWallet.publicKey
                  )
                )[0]
              );

              balance = tokenBalance?.value?.uiAmount || 0;
            } catch (e) {
              console.error(e);
              balance = 0;
            }
          }

          // end the mint when date is reached
          if (cndy?.state.endSettings?.endSettingType.date) {
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              setIsEnded(true);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    [anchorWallet, candyMachineId, rpcHost, isEnded, isPresale, connection]
  );

  useEffect(() => {
    (async () => {
      if (anchorWallet) {
        const balance = await connection.getBalance(anchorWallet!.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [anchorWallet, connection]);

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    candyMachineId,
    connection,
    isEnded,
    isPresale,
    refreshCandyMachineState,
  ]);
  console.warn = () => {};
  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletModalProvider>
            <Header />
            <Routes>
              <Route
                path="/"
                element={<Home wallet={wallet} balance={balance} />}
              ></Route>
              <Route
                path="/mint"
                element={
                  <MintPage
                    candyMachineId={candyMachineId}
                    connection={connection}
                    txTimeout={DEFAULT_TIMEOUT}
                    rpcHost={rpcHost}
                    network={network}
                  />
                }
              ></Route>
              <Route path="/*" element={<PageDoesNotExist />}></Route>
            </Routes>
            <Footer />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
      {/* </ThemeProvider> */}
    </>
  );
};

export default App;

const PageDoesNotExist = () => {
  window.location.href = "/";

  return <></>;
};
