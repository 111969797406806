import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradientButton from "../../components/GradientButton";

const SignUp = () => {
  return (
    <div className="wrapper mt-24 xl:mt-36 relative">
      <div
        style={{ maxWidth: "1270px", height: "674px", filter: "blur(120px)" }}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-green w-full opacity-10 -z-10"
      ></div>
      <div className="contain xl:flex-row flex-col justify-start items-center gap-20 lg:gap-10">
        <h2
          style={{ maxWidth: "993px" }}
          className="gr-text lg:block flex justify-start items-center gap-3 flex-col font-bold text-center lg:text-left text-4xl sm:text-6xl lg:text-7xl mid:text-7.5xl"
        >
          Bake your Beans <br /> Join PEPissimo{" "}
          {/* <GradientButton
            classes={"w-[151px] h-[55px] hover:w-[220px]"}
            text={
              <>
                PINKSALE
                <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
              </>
            }
            link="https://www.pinksale.finance/launchpad/0x5A9809cCC0A62864c207a2873860507AF8896705?chain=BSC"
          /> */}
        </h2>
      </div>
      <img
        style={{ maxWidth: "880px", width: "90%" }}
        src="/started.png"
        className="rounded-xl mt-10 object-contain"
        alt=""
      />
    </div>
  );
};

export default SignUp;
