import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Journey = () => {
  const img = useRef();
  const heading = useRef();
  const para = useRef();
  const container = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeLine = gsap.timeline({
      defaults: { duration: 0.4 },
      scrollTrigger: {
        trigger: container.current,
        start: "top 80%",
      },
    });
    timeLine
      .fromTo(img.current, { opacity: 0, x: -50 }, { opacity: 1, x: 0 }, 0.4)
      .fromTo(
        [heading.current, para.current],
        { opacity: 0, x: 50 },
        { opacity: 1, x: 0, stagger: 0.1 }
      );

    return () => {
      timeLine.kill();
    };
  }, []);

  return (
    <div ref={container} className="wrapper mt-32 xl:mt-48">
      <div className="contain flex-col justify-start items-center">
        <div
          id="journey"
          className="grid xl:grid-cols-2 place-items-center grid-cols-1 w-full gap-10 sm:gap-20 xl:gap-0"
        >
          <div className="flex justify-start items-center text-center xl:text-left xl:items-start flex-col w-full max-w-150">
            <h2
              ref={heading}
              className="text-white text-4xl sm:text-5xl font-bold mb-3"
            >
              PEPissimo’s Journey
            </h2>
            <p
              ref={para}
              style={{ color: "#b3b3b3" }}
              className="text-lg sm:text-xl font-medium"
            >
              PEPissimo has a problem. He wants to be a three-star Michelin
              chef, one of the highest kitchen honors. He works hard cooking
              every day. But nobody shows up to eat his food. It’s delicious,
              nutritious, and looks scrumptious. But the chairs are empty. Where
              is everybody? He has an idea. PEPissimo is a funny guy. He’s the
              life of the party. It’s time for PEPissimo to turn his woeful
              wojacks into opportunities using the magic of blockchain.
            </p>
          </div>
          <div className="relative flex justify-center h-full items-center xl:row-auto row-start-1 isolate">
            <img
              ref={img}
              src="/why.png"
              className="xl:max-h-68 max-w-150 xl:w-auto w-full  h-full  rounded-xl object-cover"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
