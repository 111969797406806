import { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Bite = () => {
  const container = useRef();
  const shadow = useRef();
  const heading1 = useRef();
  const img1 = useRef();
  const para1 = useRef();
  const para2 = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeLine = gsap.timeline({
      defaults: { duration: 0.3 },
      scrollTrigger: {
        trigger: container.current,
        start: "top 80%",
      },
    });

    timeLine
      .fromTo(img1.current, { opacity: 0, x: 50 }, { opacity: 1, x: 0 })
      .fromTo(
        [heading1.current, para1.current, para2.current],
        { opacity: 0, x: -50 },
        { opacity: 1, x: 0, stagger: 0.3 }
      );

    gsap.fromTo(
      shadow.current,
      { y: "30%" },
      { y: "-30%", duration: 8, repeat: -1, yoyo: true }
    );
    return () => {
      timeLine.kill();
    };
  }, []);

  return (
    <div id="why" className="wrapper mt-24 lg:mt-48 relative isolate">
      <div
        ref={shadow}
        style={{
          filter: "blur(400px)",
          maxWidth: "1600px",
          height: "700px",
          top: "10%",
          left: "-40%",
        }}
        className="absolute -z-10 opacity-20 w-full bg-green"
      ></div>

      <div className="contain flex-col justify-start items-center gap-0 xl:gap-10">
        <div
          ref={container}
          className="w-full gap-5 xl:gap-0 xl:flex-row flex-col-reverse flex justify-between items-center xl:items-stretch"
        >
          <div
            style={{ maxWidth: "550px" }}
            className="flex relative justify-start  xl:items-start text-center xl:text-left items-center flex-col gap-6"
          >
            <h3
              ref={heading1}
              style={{ color: "#FF866d" }}
              className="font-bold text-4xl mid:text-5xl"
            >
              Amore at First Bite!
            </h3>
            <p
              ref={para1}
              className="text-white text-lg sm:text-xl font-medium"
            >
              Choreographers, PEPissimo is setting TikTok and Instagram on
              FIRE... along with his kitchen but come on, fuhgeddaboudit. We're
              here to create a trend that's as enduring as Italian cuisine
              itself, and we’re using metaverse kitchens to do it!
            </p>
            <p
              ref={para2}
              className="text-white text-lg sm:text-xl font-medium"
            >
              PEPissimo is a feast for crypto enthusiasts and meme lovers,
              dancing his way into your digital wallet and your heart. From
              seasoned crypto connoisseurs to those just dipping their toes into
              the vast sea of digital currency, PEPissimo offers a platform
              that's as user-friendly as it is fun. We're committed to serving
              up a coin that's easy to use, easy to enjoy, and hard to forget.
            </p>
          </div>
          <img
            ref={img1}
            src="/bite.png"
            className="w-full rounded-xl object-cover max-w-150"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Bite;
