import styled from "styled-components";
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { GatewayStatus, useGateway } from "@civic/solana-gateway-react";
import { CandyMachineAccount } from "./candy-machine";

export const CTAButton = styled(Button)`
  display: block !important;
  margin: 0 auto !important;
  background-color: var(--title-text-color) !important;
  min-width: 120px !important;
  font-size: 1em !important;
`;

export const MintButton = ({
  onMint,
  candyMachine,
  isMinting,
  isEnded,
  isActive,
  isSoldOut,
}: {
  onMint: () => Promise<void>;
  candyMachine?: CandyMachineAccount;
  isMinting: boolean;
  isEnded: boolean;
  isActive: boolean;
  isSoldOut: boolean;
}) => {
  const { requestGatewayToken, gatewayStatus } = useGateway();
  const [clicked, setClicked] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    setIsVerifying(false);
    if (
      gatewayStatus === GatewayStatus.COLLECTING_USER_INFORMATION &&
      clicked
    ) {
      // when user approves wallet verification txn
      setIsVerifying(true);
    } else if (gatewayStatus === GatewayStatus.ACTIVE && clicked) {
      console.log("Verified human, now minting...");
      onMint();
      setClicked(false);
    }
  }, [gatewayStatus, clicked, setClicked, onMint]);

  return (
    <button
      className="transition-all mx-auto duration-700 origin-left rounded-full flex justify-center items-center bg-btnBg relative isolate border-none text-white cursor-pointer group font-semibold text-base "
      disabled={
        clicked ||
        candyMachine?.state.isSoldOut ||
        isSoldOut ||
        isMinting ||
        isEnded ||
        !isActive ||
        isVerifying
      }
      onClick={async () => {
        if (
          isActive &&
          candyMachine?.state.gatekeeper &&
          gatewayStatus !== GatewayStatus.ACTIVE
        ) {
          console.log("Requesting gateway token");
          setClicked(true);
          await requestGatewayToken();
        } else {
          console.log("Minting...");
          await onMint();
        }
      }}
    >
      <p className="mint-btn gap-2 bg-btnBg rounded-full group-hover:bg-lightGreen transition-all duration-700 flex justify-center items-center z-10">
        {!candyMachine ? (
          "CONNECTING..."
        ) : candyMachine?.state.isSoldOut || isSoldOut ? (
          "SOLD OUT"
        ) : isActive ? (
          isVerifying ? (
            "VERIFYING..."
          ) : isMinting || clicked ? (
            <CircularProgress />
          ) : (
            "MINT NFT*"
          )
        ) : isEnded ? (
          "ENDED"
        ) : candyMachine?.state.goLiveDate ? (
          "SOON"
        ) : (
          "UNAVAILABLE"
        )}
      </p>
      <div className="absolute -inset-0.5 bg-btnGr rounded-full"></div>
    </button>
  );
};
