import gsap from "gsap";
import { useEffect, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";

const Community = () => {
  const container2 = useRef();
  const img = useRef();
  const heading = useRef();
  const para = useRef();
  const para2 = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeLine = gsap.timeline({
      defaults: { duration: 0.4 },
      scrollTrigger: {
        trigger: container2.current,
        start: "top 80%",
      },
    });
    timeLine
      .fromTo(img.current, { opacity: 0, x: -50 }, { opacity: 1, x: 0 })
      .fromTo(
        [heading.current, para.current, para2.current],
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, stagger: 0.2 }
      );

    return () => {
      timeLine.kill();
    };
  }, []);

  return (
    <div
      id="community"
      ref={container2}
      className="wrapper mt-24 relative sm:mt-48"
    >
      <div
        style={{
          left: "20%",
          filter: "blur(115px)",
          opacity: "0.15",
          maxWidth: "1130px",
          height: "600px",
        }}
        className="absolute w-full -z-10 top-0 sm:top-1/2 sm:-translate-y-1/2 bg-green"
      ></div>
      <div className="contain flex-col xl:flex-row justify-center items-center xl:items-stretch gap-14 isolate relative">
        <div ref={img} className="relative xl:w-full">
          <img
            src="/community.png"
            style={{}}
            className="w-full max-w-150 h-full rounded-xl xl:max-w-187 object-cover"
            alt=""
          />
        </div>
        <div
          style={{ maxWidth: "650px" }}
          className="flex  justify-start items-start flex-col gap-5"
        >
          <h2
            ref={heading}
            style={{ lineHeight: "120%" }}
            className="text-white sm:mb-7 text-4xl sm:text-5xl font-bold"
          >
            The PEPissimo Secret Sauce: Community
          </h2>
          <p
            ref={para}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            We've taken the charm of Pepe the Frog, added a sprinkle of Italian
            flair, and turned him into the most captivating chef around -
            Pepissimo. Our mission? Cooking up sizzling crypto gains for our
            community. With Pepissimo, you're not just investing; you're joining
            a family that knows how to balance the books and the laughs.
          </p>
          <p
            ref={para2}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            Dive into the world of PEPissimo and taste the difference for
            yourself. With a community as warm as a bowl of minestrone and a
            coin as reliable as a Margherita pizza, we're changing the game.
            Remember, PEPissimo isn't just a coin; it's a lifestyle.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Community;
