import { Link } from "react-router-dom";

const GradientButton = ({ text, classes, link }) => {
  return link ? (
    <Link
      to={link}
      className={`no-underline transition-all duration-700 origin-left rounded-full flex justify-center items-center bg-btnBg relative isolate border-none text-white cursor-pointer group font-semibold text-base px-8 gradient-btn ${classes}`}
    >
      <p className="w-full h-full gap-2 rounded-full transition-all duration-700 flex justify-center items-center z-10">
        {text}
      </p>
      <div className="absolute -inset-0.5 bg-btnGr -z-10 rounded-full"></div>
    </Link>
  ) : (
    <button
      className={` transition-all duration-700 origin-left rounded-full flex justify-center items-center bg-btnBg relative isolate border-none text-white cursor-pointer group font-semibold text-base ${classes}`}
    >
      <p className="w-full h-full gap-2 bg-btnBg rounded-full group-hover:bg-lightGreen transition-all duration-700 flex justify-center items-center z-10">
        {text}
      </p>
      <div className="absolute -inset-0.5 bg-btnGr -z-10 rounded-full"></div>
    </button>
  );
};

export default GradientButton;
