import { MintButton } from "../../MintButton";
import Home from "./Home";

const MintPage = ({
  candyMachineId,
  connection,
  txTimeout,
  rpcHost,
  network,
}) => {
  return (
    <>
      <Home
        candyMachineId={candyMachineId}
        connection={connection}
        txTimeout={txTimeout}
        rpcHost={rpcHost}
        network={network}
      />
      {/* <div className="wrapper  mt-36 ">
        <div className="contain flex-col justify-start items-center gap-10">
          <video
            autoPlay
            loop
            controls
            muted
            playsInline
            className="rounded-xl object-contain"
            style={{ maxHeight: "600px" }}
            src="/mint.mp4"
          ></video>
        
        </div>
      </div> */}
    </>
  );
};

export default MintPage;
