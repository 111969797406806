import gsap from "gsap";
import { useEffect, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";

const Influencer = () => {
  const container2 = useRef();
  const img = useRef();
  const heading = useRef();
  const para = useRef();
  const para2 = useRef();
  const para3 = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeLine = gsap.timeline({
      defaults: { duration: 0.4 },
      scrollTrigger: {
        trigger: container2.current,
        start: "top 80%",
      },
    });
    timeLine
      .fromTo(img.current, { opacity: 0, x: -50 }, { opacity: 1, x: 0 })
      .fromTo(
        [heading.current, para.current, para2.current, para3.current],
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, stagger: 0.2 }
      );

    return () => {
      timeLine.kill();
    };
  }, []);

  return (
    <div
      id="community"
      ref={container2}
      className="wrapper mt-24 relative sm:mt-48"
    >
      <div
        style={{
          left: "20%",
          height: "600px",
          maxWidth: "1130px",
          filter: "blur(115px)",
          opacity: "0.15",
        }}
        className="absolute w-full -z-10 top-0 sm:top-1/2 sm:-translate-y-1/2 bg-green"
      ></div>
      <div className="contain flex-col xl:flex-row justify-center items-center xl:items-stretch gap-14 isolate relative">
        <div ref={img} className="relative xl:w-full">
          <img
            style={{ maxHeight: "530px" }}
            src="/influencer.png"
            className="w-full rounded-xl  object-contain"
            alt=""
          />
        </div>
        <div
          style={{ maxWidth: "650px" }}
          className="flex justify-start items-start flex-col gap-5"
        >
          <h2
            ref={heading}
            className="text-white sm:mb-7 text-4xl sm:text-5xl font-bold"
          >
            More Than Just a Meme: PEPissimo Goes Viral!
          </h2>
          <p
            ref={para}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            PEPissimo is not your ordinary meme. We're breaking boundaries and
            making waves in the crypto world. With our published song on iTunes
            and Spotify, and an exciting collaboration with dancers and dance
            schools, PEPissimo is set to go viral!
          </p>
          <p
            ref={para2}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            Join the movement and witness the fusion of music and dance that
            will captivate audiences worldwide. Stay tuned as we push the
            boundaries of viral fame on platforms like TikTok, YouTube, Spotify,
            iTunes and more.
          </p>
          <p
            ref={para3}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            PEPissimo: More than just a meme, it's a global
            sensation in the making!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Influencer;
