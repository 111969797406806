import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import React from "react";
import styled from "styled-components";

const WalletButton = ({ wallet, balance, col = false }) => {
  const WalletContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
  `;

  const WalletAmount = styled.div`
    color: white;
    width: auto;
    padding: 5px 5px 5px 16px;
    min-width: 48px;
    min-height: auto;
    border-radius: 22px;
    background-color: var(--main-text-color);
    box-shadow: unset;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
    border: 0;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: flex-start;
    gap: 10px;
  `;

  const Wallet = styled.ul`
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
  `;

  const ConnectButton = styled(WalletMultiButton)`
    border-radius: 18px !important;
    padding: 6px 16px;
    background-color: #111;
    margin: 0 auto;
  `;

  return (
    <WalletContainer>
      <Wallet>
        {wallet ? (
          <WalletAmount className={col && `flex-col`}>
            {(balance || 0).toLocaleString()} SOL
            <div className="relative flex items-center">
              <span className="absolute -left-16 w-max">Step 1:</span>

              <ConnectButton className="select-wallet" />
            </div>
          </WalletAmount>
        ) : (
          <ConnectButton>Connect Your Wallet</ConnectButton>
        )}
      </Wallet>
    </WalletContainer>
  );
};

export default WalletButton;
