import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useEffect, useRef } from "react";

import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

import "@splidejs/react-splide/dist/css/splide-core.min.css";
import "@splidejs/react-splide/dist/css/splide.min.css";
import WalletButton from "../../components/WalletButton.jsx";
import GradientButton from "../../components/GradientButton.jsx";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const sliderImgs = [
  "/slider-1.jpg",
  "/slider-2.jpg",
  "/slider-3.jpg",
  "/slider-4.jpg",
  "/slider-5.jpg",
  "/slider-6.jpg",
  "/slider-7.jpg",
  "/slider-8.jpg",
  "/slider-9.jpg",
  "/slider-10.jpg",
];

const Slider = ({ wallet, balance }) => {
  const heading = useRef();
  const container = useRef();
  const btn1 = useRef();
  const btn2 = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeLine = gsap.timeline({
      defaults: { duration: 0.4 },
      scrollTrigger: {
        trigger: container.current,
        start: "top 80%",
      },
    });

    timeLine.fromTo(
      [btn1.current, heading.current, btn2.current],
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, stagger: 0.2 }
    );

    return () => {
      timeLine.kill();
    };
  }, []);

  return (
    <div ref={container} className="mt-24 sm:mt-28 xl:mt-48 wrapper gap-14">
      <div className="flex flex-col items-center contain">
        <div ref={btn1}>
          <WalletButton wallet={wallet} balance={balance} col />
        </div>

        <h2
          ref={heading}
          style={{ lineHeight: "120%" }}
          className="text-white my-5 text-3xl lg:text-5xl font-bold text-center"
        >
          Mint your Unique Pepissimo for ONLY 1 Solana
        </h2>

        <div ref={btn2} className="relative flex items-center">
          <span className="absolute -left-16 w-max text-white">Step 2*:</span>
          <GradientButton
            classes={"h-14 hover:w-56"}
            text={
              <>
                MINT NFT*
                <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
              </>
            }
            link="/mint"
          />
        </div>

        <span className="text-white mt-6 -mb-7">
          *if you want to mint the nft in on your smartphone, copy the mintpage
          url to your walletbrowser
        </span>
      </div>

      <Splide
        options={{
          perPage: 3,
          perMove: 1,
          type: "loop",
          gap: 12,
          padding: 120,
          autoplay: true,
          interval: 4000,
          pauseOnHover: false,
          pauseOnFocus: false,
          arrows: false,
          pagination: false,
          drag: false,
          breakpoints: {
            1200: {
              padding: 80,
            },

            900: {
              perPage: 2,
            },

            675: {
              perPage: 1,
              padding: 120,
            },

            500: {
              padding: 80,
            },

            420: {
              padding: 40,
            },
          },
        }}
      >
        {sliderImgs.map((img, idx) => (
          <SplideSlide key={idx}>
            <img src={img} alt="slider image" />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default Slider;
