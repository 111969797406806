import { faBars, faMusic, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  faFacebookF,
  faInstagram,
  faSpotify,
  faTelegram,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Header = () => {
  const [headerToggle, setHeaderToggle] = useState(false);
  const [bg, setBg] = useState(false);
  useEffect(() => {
    //header bg when you scroll
    const showBg = () => {
      if (window.scrollY > 60) {
        setBg(true);
      } else {
        setBg(false);
      }
    };
    window.addEventListener("scroll", showBg);
    return () => {
      window.removeEventListener("scroll", showBg);
    };
  }, []);
  //header movement depending on scroll
  useEffect(() => {
    const body = document.querySelector("html");
    if (headerToggle) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [headerToggle]);

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);

    if (!section) return;

    section.scrollIntoView({
      behaviour: "smooth",
    });
  };

  return (
    <div
      className={`wrapper fixed top-0 left-0 z-50 transition-all duration-500 ${
        bg ? "bg-headerScroll" : ""
      }`}
    >
      <div className="contain h-28 justify-between items-center">
        <Link to="/">
          <img src="/logo.webp" className="w-24 object-contain" alt="" />
        </Link>
        {headerToggle && (
          <div
            onClick={() => setHeaderToggle(false)}
            className="fixed top-0 left-0 w-full h-full z-40 bg-black opacity-60"
          ></div>
        )}
        <nav
          className={`border-l-2 overflow-y-auto  head:overflow-visible head:border-none border-solid border-white head:static fixed top-0 transition-all duration-1000 ${
            headerToggle ? "right-0" : "-right-full"
          } h-full head:w-auto head:max-w-none w-full max-w-full sm:max-w-100 flex justify-start head:items-center gap-6 pb-8 pt-28 sm:pt-24 px-8 head:p-0 z-50 head:gap-5  xl:gap-10 head:flex-row flex-col items-center sm:items-start bg-brokenTube head:bg-transparent`}
        >
          <div className="flex justify-start items-center head:w-auto w-full sm:items-start flex-col head:flex-row head:items-center gap-6 mid:gap-4 xl:gap-5">
            <Link
              onClick={() => {
                scrollToSection("#community");
                setHeaderToggle(false);
              }}
              to="/#community"
              className="no-underline flex justify-start items-center gap-2 text-white text-opacity-80 hover:text-white hover:brightness-200 text-lg head:text-base xl:text-base font-medium"
            >
              Community{" "}
            </Link>
            <Link
              onClick={() => {
                scrollToSection("#why");
                setHeaderToggle(false);
              }}
              to="/#why"
              className="no-underline flex justify-start items-center gap-2 text-white text-opacity-80 hover:text-white hover:brightness-200 text-lg head:text-base xl:text-base font-medium"
            >
              Why
            </Link>
            <Link
              to="/mint"
              className="no-underline flex justify-start items-center gap-2 text-white text-opacity-80 hover:text-white hover:brightness-200 text-lg head:text-base xl:text-base font-medium"
            >
              Minting
            </Link>
            <div className="flex justify-start flex-wrap items-center head:gap-3 gap-5">
              {" "}
              <a href="https://t.me/pepissimo" target={"blank"}>
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faTelegram}
                />
              </a>
              <a href="https://twitter.com/thepepissimo" target={"blank"}>
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faTwitter}
                />
              </a>
              <a href="https://www.tiktok.com/@thepepissimo" target={"blank"}>
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faTiktok}
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100093023433454"
                target={"blank"}
              >
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faFacebookF}
                />
              </a>
              <a href="https://www.instagram.com/thepepissimo" target={"blank"}>
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faInstagram}
                />
              </a>
              <a
                href="https://www.youtube.com/@PepissimoDance"
                target={"blank"}
              >
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faYoutube}
                />
              </a>
              <a
                href="https://open.spotify.com/track/1iCrmpTq8452K5X4UcAve2"
                target={"blank"}
              >
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faSpotify}
                />
              </a>
              <a
                href="https://music.apple.com/us/album/pepissimo-dance/1688552034?i=1688552035"
                target={"blank"}
              >
                <FontAwesomeIcon
                  className="text-white text-2xl hover:text-lightPink transition-all"
                  icon={faMusic}
                />
              </a>
            </div>
          </div>
        </nav>
        <FontAwesomeIcon
          onClick={() => setHeaderToggle((prev) => !prev)}
          icon={headerToggle ? faXmark : faBars}
          className="relative z-50 text-white head:hidden block text-2xl cursor-pointer"
        />
      </div>
    </div>
  );
};

export default Header;
