import React, { useEffect, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

const Disclaimer = () => {
  const container = useRef();
  const heading = useRef();
  const para1 = useRef();
  const para2 = useRef();
  const para3 = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeLine = gsap.timeline({
      defaults: { duration: 0.4 },
      scrollTrigger: {
        trigger: container.current,
        start: "top 80%",
      },
    });

    timeLine.fromTo(
      [heading.current, para1.current, para2.current, para3.current],
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, stagger: 0.2 }
    );

    return () => {
      timeLine.kill();
    };
  }, []);

  return (
    <div ref={container} className="wrapper mt-24 sm:mt-36">
      <div className="contain text-center flex-col">
        <h2
          ref={heading}
          className="text-white mb-4 sm:mb-7 text-2xl sm:text-3xl font-bold"
        >
          DISCLAIMER REGARDING THE TOKENS RESTRICTED FOR US PERSONS
        </h2>
        <div className="flex flex-col gap-5">
          <p
            ref={para1}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            You shall not purchase or otherwise acquire any of our token
            products if you are a citizen, resident (tax or otherwise) of the
            United States of America, or if you are a person in any jurisdiction
            in which such offer, sale, and/or purchase of any of our token
            products is unlawful, prohibited, or unauthorized (together with
            U.S. citizens, residents, a “Restricted Person”).
          </p>
          <p
            ref={para2}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            The term “Restricted Person” includes, but is not limited to, any
            natural person residing in, or any firm, company, partnership,
            trust, corporation, entity, government, state or agency of a state,
            or any other incorporated or unincorporated body or association,
            association or partnership (whether or not having separate legal
            personality) that is established and/or lawfully existing under the
            laws of, a jurisdiction in which such offer, sale, and/or purchase
            of any of our token products is unlawful, prohibited, or
            unauthorized).
          </p>
          <p
            ref={para3}
            style={{ color: "#b3b3b3" }}
            className="text-lg mid:text-xl font-medium"
          >
            You shall not resell or otherwise transfer any of our token products
            to any Restricted Person, including but not limited to, citizens,
            residents of the United States of America or any natural person or
            entity within the United States of America. The transfer or resale
            of any of our token products to any Restricted Person is not
            permitted.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
