import Hero from "./Hero";
import Slider from "./Slider";
import Disclaimer from "./Disclaimer";
import SignUp from "./SignUp";
import Influencer from "./Influencer";
import Bite from "./Bite";
import Community from "./Community";
import Journey from "./Journey";

const Home = ({ wallet, balance }) => {
  return (
    <>
      <Hero />
      <Slider wallet={wallet} balance={balance} />
      <Journey />
      <Community />
      <Bite />
      <Influencer />
      <SignUp />
      <Disclaimer />
    </>
  );
};

export default Home;
