import GradientButton from "../../components/GradientButton";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";

const Hero = () => {
  const heading = useRef();
  const underline = useRef();
  const para = useRef();
  const timer = useRef();
  const img = useRef();
  const logos = useRef();
  const btns = useRef();
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();
  const startTimer = () => {
    let end = new Date("5/24/2023 5:00 PM");
    interval = setInterval(() => {
      let _second = 1000;
      let _minute = _second * 60;
      let _hour = _minute * 60;
      let _day = _hour * 24;
      let now = new Date();
      let nowUTC = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      );
      let distance = end - nowUTC;
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
      if (distance < 0) {
        clearInterval(interval.current);
        return;
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };
  useEffect(() => {
    const timeLine = gsap.timeline({
      defaults: { duration: 0.25 },
    });
    timeLine
      .fromTo(heading.current, { opacity: 0, x: -80 }, { opacity: 1, x: 0 })
      .fromTo(
        underline.current,
        { opacity: 0, width: 0 },
        { opacity: 1, width: "100%" }
      )
      .fromTo(para.current, { opacity: 0, x: -80 }, { opacity: 1, x: 0 })
      .fromTo(timer.current, { opacity: 0, x: -80 }, { opacity: 1, x: 0 })
      .fromTo(logos.current, { opacity: 0, y: 80 }, { opacity: 1, y: 0 })
      .fromTo(btns.current, { opacity: 0, y: 80 }, { opacity: 1, y: 0 })
      .fromTo(img.current, { opacity: 0, x: 80 }, { opacity: 1, x: 0 });
    return () => {
      timeLine.kill();
    };
  }, []);
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });
  return (
    <div className="wrapper pt-36 min-h-150 xl:pt-44 relative">
      <div
        style={{ filter: "blur(111px)" }}
        className="absolute explore left-20% top-0 bg-greenRadial -z-10 w-full  max-w-4xl h-200 mix-blend-screen opacity-25"
      ></div>
      <div className="contain  flex-col xl:flex-row xl:gap-0 gap-10 justify-around items-center xl:items-stretch">
        <div className="flex xl:w-1/2 justify-start items-center text-center xl:text-left xl:items-start xl:max-w-137 max-w-150 flex-col gap-5">
          <h2
            ref={heading}
            style={{ lineHeight: "120%" }}
            className="font-bold text-4xl sm:text-5xl xl:text-6xl text-white"
          >
            <span className="gr-text relative">
              PEPissimo:{" "}
              <span
                ref={underline}
                className="absolute left-0 top-full h-0.5 bg-blueGr"
              ></span>
            </span>{" "}
            Blending Memes and Marinara
          </h2>
          <p
            ref={para}
            style={{ maxWidth: "724px" }}
            className="text-white text-opacity-75 text-base sm:text-xl font-medium"
          >
            Welcome to PEPissimo, where the best dank memes meet the majesty of
            Italian cuisine. PEPissimo is where the richness of lasagna layers
            with the humor of the internet, creating a memecoin as irresistible
            as Nonna's homemade ravioli. We’re rolling out the pizza to try and
            make you some dough, so grab a slice and join the party!
          </p>
        </div>
        <video
          ref={img}
          className="xl:w-1/2 object-cover"
          src="/video.mp4"
          muted
          playsInline
          controls
          loop
          autoPlay
        ></video>
      </div>
      {/* <div className="contain flex-col gap-10 justify-start items-center mt-10">
        <div ref={timer} className="grid grid-cols-4 gap-8 mt-4">
          <div className="flex justify-start items-center flex-col gap-3">
            <h4 className="  rounded-xl text-primary text-green grid place-items-center text-[34px] sm:text-[55px] xl:text-[63px] font-bold ">
              {timerDays}
            </h4>
            <p className="text-white font-bold">Days</p>
          </div>
          <div className="flex justify-start items-center flex-col gap-3">
            <h4 className="  rounded-xl text-primary text-green grid place-items-center text-[34px] sm:text-[55px] xl:text-[63px] font-bold ">
              {timerHours}
            </h4>
            <p className="text-white font-bold">Hours</p>
          </div>
          <div className="flex justify-start items-center flex-col gap-3">
            <h4 className="  rounded-xl text-primary text-green grid place-items-center text-[34px] sm:text-[55px] xl:text-[63px] font-bold ">
              {timerMinutes}
            </h4>
            <p className="text-white font-bold">Minutes</p>
          </div>
          <div className="flex justify-start items-center flex-col gap-3">
            <h4 className="  rounded-xl text-primary text-green grid place-items-center text-[34px] sm:text-[55px] xl:text-[63px] font-bold ">
              {timerSeconds}
            </h4>
            <p className="text-white font-bold">Seconds</p>
          </div>
        </div>
        <div
          ref={logos}
          className="flex flex-wrap justify-start items-center gap-5"
        >
          <a href="#" target="blank">
            <img
              src="/bsc.webp"
              alt=""
              className="max-w-[150px] object-contain"
            />
          </a>
          <a href="#" target="blank">
            <img
              src="/smile.webp"
              alt=""
              className="max-w-[150px] object-contain"
            />
          </a>
          <a href="#" target="blank">
            <img
              src="/pancake.png"
              alt=""
              className="max-w-[250px] object-contain"
            />
          </a>
        </div>
        <div
          ref={btns}
          className="grid max-w-[700px] mt-5 grid-cols-3 w-full gap-5"
        >
          <GradientButton
            classes={"w-full h-[55px] "}
            text={
              <>
                Audit
                <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
              </>
            }
            link="https://www.cyberscope.io/audits/pepissimo"
          />
          <GradientButton
            classes={"w-full h-[55px] "}
            text={
              <>
                KYC
                <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
              </>
            }
            link="https://pinksale.notion.site/PEPissimo-KYC-Verification-98f3e0f7901c44c5a779abd83ea29fb5"
          />
          <GradientButton
            classes={"w-full h-[55px] "}
            text={
              <>
                SAFU
                <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
              </>
            }
            link="/safu.pdf"
          />
        </div>
      </div> */}
    </div>
  );
};

export default Hero;
